.App {
  text-align: -webkit-center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tr_elems{
  cursor: pointer;
}

.box{
  box-shadow: 2px 2px 3px gray;
  border: 2px solid black;
  border-radius: 5px;

}
.modalbox{
  height: 80%;
}

table {
  border-collapse:collapse;
  table-layout:fixed;
}

td{
  /* border:1px solid #ccc; */
  padding:5px 10px;
  vertical-align:top;
  word-break:break-word;
}
.iframeApp{
  height: 100%;
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23FF0000" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="24" text-anchor="middle" font-weight="bold">Loading...</text></svg>') 0px 0px no-repeat;
}
